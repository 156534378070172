import React, {Component} from 'react';
import Polaroid from "./Polaroid";
import Playlist from "./FormPlaylist";
import Map from "./Map";
import Popup from "./Popup";
import CopyToClipboard from "./CopyToClipboard";

class WeddingDetails extends Component {

    goToRSVP() {
        document.getElementById('rsvp').scrollIntoView({behavior: "smooth"});
    }

    renderVenue() {
        return (
            <div id="Venue" className="section-content">
                <h2>The Venue</h2>
                <a className="cc-logo" href="https://www.cornacchi.com/" target="_blank" rel="noreferrer">
                    <img src="/assets/images/casa-cornacchi-logo.svg" alt='Casa Cornacchi Logo' />
                </a>
                <span className="caption">(click logo to visit website)</span>
                <p>
                    We are so excited about Casa Cornacchi!<br/>
                    A gorgeous farmhouse nestled in the rolling hills of the Arezzo region of Italy. <br/>
                    The area is host to incredible food, friendly locals and rich culture. <br/>
                    Casa Cornacchi will be our backdrop for 3 days of festivities.
                </p>
                <div className="polaroid-group">
                    <Polaroid imgSrc={'CC-pool.jpg'} imgAlt={'Casa Cornacchi Pool'}
                              tilt={'left'} clickToEnlargeModalId={"imgModal"} />
                    <Polaroid imgSrc={'CasaCornacchi.jpg'} imgAlt={'Casa Cornacchi'}
                              clickToEnlargeModalId={"imgModal"} />
                    <Polaroid imgSrc={'CC-pool2.jpg'} imgAlt={'Casa Cornacchi Pool'}
                              tilt={'right'} clickToEnlargeModalId={"imgModal"} />
                </div>
            </div>
        )
    }

    renderTravel() {
        return (
            <div id="Travel" className="section-content">
                <h2>Travel</h2>
                <div className="loc-details-container">
                    <div className="loc-details-content">
                        <h3>Address</h3>
                        <p>Casa Cornacchi <br/>
                            Via Capitan Goro, 12 <br/>
                            52021 Bucine Arezzo <br/>
                            Tuscany, Italy <br/>
                            <a href="https://g.page/cornacchi?share" target="_blank" rel="noreferrer">
                                <img src="/assets/images/Google_Maps_icon.png" className="loc-map"
                                     title="Click to see on the Map" alt='Google Maps link'/>
                            </a>
                            <br/>
                            What 3 Words Location:<br/>
                            <a href="https://w3w.co/advertised.latecomers.rushing"
                               target="_blank"
                               rel="noreferrer" title="What 3 Words">
                                &#47;&#47;&#47;advertised.latecomers.rushing
                            </a>
                        </p>
                    </div>
                    <div className="loc-details-content">
                        <h3>Venue Contact</h3>
                        <p>
                            Phone: <br/>+39 055 998229<br/>
                            <span className="hr"/>
                            Phone / Whatsapp: <br/>+39 393 8509814 <br/>(Info & bookings)<br/>
                            <span className="hr"/>
                            Phone: <br/>+ 39 3409930310 <br/>(Administration)<br/>
                        </p>
                    </div>
                    <div className="loc-details-content">
                        <h3>Closest Airports</h3>
                        <p>
                            Florence<br/>
                            <span className="small">(~85km/~1hr 10mins)</span><br/>
                            <span className="hr"/>
                            Pisa<br/>
                            <span className="small">(~150km/~1hr 50mins)</span><br/>
                            <span className="hr"/>
                            Rome<br/>
                            <span className="small">(~250km/~2hr 45mins)</span>
                        </p>
                    </div>
                    <div className="loc-details-content">
                        <h3>Important Note</h3>
                        <p>
                            Casa Cornacchi does not have a street name. You will need to rely on signs.
                            Put "Montebenichi" as the final destination in your GPS. On the way to
                            the village of Montebenichi you will find signs to Casa Cornacchi on the road.
                        </p>
                    </div>
                </div>
            </div>
        )
    }

    renderAccommodation() {
        return (
            <div id="Accommodation" className="section-content">
                <h2>Accommodation</h2>
                <p>
                    We have reserved the villa exclusively for 3 nights,  which includes breakfast each morning.<br/>
                    Our immediate family and wedding party will have first right of refusal, and then we will open
                    the remaining rooms up to all guests. <br/>
                    If you are interested in staying at the venue, please
                    note when <span className="link" onClick={() => this.goToRSVP()}>RSVPing</span>.
                </p>
                <p>
                    There are many accommodation options close to the wedding venue. <br/>
                    Our wedding planners, Hannah and Mari, are happy to help you find a great place to stay. <br/>
                </p>
                <h3>Our Wedding Planners :<br/>Love Story Events</h3>
                <div className="section-group">
                    <div className="section-content">
                        <div className="circle-pic hannah-pic"/>
                        <h3>Hannah<span className="small"> - Director</span></h3>
                        <p>
                            Hannah has 12 years experience in the event industry with a portfolio that includes The
                            Royal Family, Ritz Hotel and lots of beautiful weddings on the Amalfi Coast, Tuscany,
                            Sicily and Venice. Having personally been through the destination wedding process, she
                            set up Love Story Italy to offer a bespoke and high quality approach, providing couples
                            with efficient support and meticulous planning – as she knows how fundamental this is to
                            creating a successful memorable day.
                        </p>
                    </div>
                    <div className="section-content">
                        <div className="circle-pic mari-pic"/>
                        <h3>Mariangela<span className="small"> - Senior Wedding Planner</span></h3>
                        <p>
                            Mari is based on the Amalfi Coast. She brings years of hospitality experience as well as
                            a high focus on design and detail to the Love Story Italy team. Her passion is not only
                            wedding planning, but languages, with 4 under her belt: English, Italian, Chinese
                            Mandarin and Spanish! Mari is the ears to the ground in Italy and a great source of
                            local knowledge.
                        </p>
                    </div>
                </div>
                <div className="polaroid-group">
                    <Polaroid imgSrc={'LoveStory-HannahMari.jpeg'} imgAlt={'Hannah and Mari at Love Story Events'}
                              tilt={'left'} clickToEnlargeModalId={"imgModal"} />
                    <Polaroid imgSrc={'LoveStory-HannahMari2.jpeg'} imgAlt={'Hannah and Mari at Love Story Events'}
                              tilt={'right'} clickToEnlargeModalId={"imgModal"} />
                </div>
                <p>
                    Please contact Hannah or Mari via their&nbsp;
                    <a href="https://lovestoryitaly.com/" target="_blank" rel="noreferrer">website</a>
                    &nbsp;or by&nbsp;
                    <a href="mailto:hannah@lovestoryevents.co.uk?subject=Michele Caneppele and James Draycott wedding">email</a>
                </p>
            </div>
        )
    }

    renderSchedule() {
        return (
            <div id="Schedule" className="section-content">
                <h2>Wedding Schedule</h2>
                <ul className="schedule">
                    <li>
                        <span className="date">July 7 <sup>th</sup> 2022 from 7:00 pm</span><br/>
                        The Warm Up<br/><br/>
                        Bubbles, beer, and classic pizzas will be flowing fresh out of the wood-fired ovens, alongside
                        light nibbles of focaccias with cured meats, cheese and salad.
                        Dessert will be a nutella pizza or gelato/sorbet. <br/>
                        Gluten free or vegan pizzas will be available on request. <br/><br/>
                        We ask for a contribution of €30pp towards the cost of the food, bubbles and beer.<br/>
                        If you're interested in attending please note
                        when <span className="link" onClick={() => this.goToRSVP()}>RSVPing</span>.
                        <span className="hr"/>
                    </li>
                    <li>
                        <span className="date">July 8 <sup>th</sup> 2022</span><br/>
                        Let's Celebrate<br/><br/>
                        An afternoon / evening filled with food, drink and
                        entertainment overlooking the Tuscan countryside.<br/><br/>
                        <ul className="list">
                            <li>5:00 pm - Guest Arrival</li>
                            <li>5:30 pm - Ceremony</li>
                            <li>Cocktail hour with bubbles, spritz, beer and an array of Italian nibbles</li>
                            <li>4 course Tuscan dinner with local wines</li>
                            <li>Cake, Dancing &amp; an Open Bar</li>
                            <li>1:00 am - Carriages</li>
                        </ul>
                        <span className="hr"/>
                    </li>
                    <li>
                        <span className="date">July 9 <sup>th</sup> 2022 from 4:00 pm to 8:00 pm</span><br/>
                        Eat, Drink &amp; Recover<br/><br/>
                        Please join us to relax & recover by the pool at Casa Cornacchi. The Cornacchi chefs will
                        get your taste buds salivating with a BBQ feast in the late afternoon. There will be a
                        selection of meats, seafood, grilled vegetables and salads.<br/><br/>
                        We ask for a contribution towards the event of €40pp.<br/>
                        If you're interested in attending please note
                        when <span className="link" onClick={() => this.goToRSVP()}>RSVPing</span>.
                    </li>
                </ul>
            </div>
        )
    }

    renderWeddingParty() {
        return (
            <div id="WeddingParty" className="section-content">
                <h2>Wedding Party</h2>
                <div className="section-group">
                    <div className="section-content">
                        <h2>Bridal Party</h2>
                        <div className="circle-pic wendy-pic"/>
                        <h3>Wendy - Maid of Honour</h3>
                        <p>
                            Michele and Wendy met in London through a mutual Aussie friend over 10 years ago.
                            Crazy London brunches, wild nights out & a number of travel adventures (some involving
                            the police) are a few of the highlights of their friendship. Wendy now lives in northern
                            Sweden with her prince charming and little girl.
                        </p>
                        <div className="circle-pic kat-pic"/>
                        <h3>Kat - Bridesmaid</h3>
                        <p> Cachaca, waterfalls and being escorted by Arnie & Danny (stray dogs) to / from bars - this
                            is how Kat and Michele met in Paraty, Brazil.
                            A girls trip to Rio cemented their friendship. Kat now lives just outside Leeds, and
                            Michele is godmother to her son.
                        </p>
                    </div>
                    <div className="section-content">
                        <h2>Groomsmen</h2>
                        <div className="circle-pic charlie-pic"/>
                        <h3>Charlie - Best Man</h3>
                        <p>Charlie and James have been best friends ever since James showed Charlie around the
                            school when they were 11 years old. They are often heading out on their motorbikes
                            camping or in the pub talking about the next trip.</p>
                        <div className="circle-pic peter-pic"/>
                        <h3>Peter - Groomsman</h3>
                        <p>James's older brother, who lives in Lymington with his wife Harriet, and 4 children
                            Bella, Freddie, Sophie & Rocket. Peter took over the family business after Dad passed
                            away in 2016 and cooks up Dad's Paella recipe whenever the family congregate.</p>
                    </div>
                </div>
            </div>
        )
    }

    renderRegistry() {
        const spanStyleNoTopBorder = {
            display: 'block',
            whiteSpace: 'nowrap',
            textAlign: 'left',
            padding: '6px 6px 6px 6px',
            fontSize: '11pt'
        }, spanStyle = {
            display: 'block',
            whiteSpace: 'nowrap',
            textAlign: 'left',
            padding: '6px 6px 6px 6px',
            fontSize: '11pt',
            borderTop: '1px solid var(--sageLight)'
        }

        return (
            <div id="Registry" className="section-content">
                <h2>Registry</h2>
                <p>
                    Your presence at our wedding is a gift enough. <br/>
                    But if you would like to contribute towards our honeymoon
                    please&nbsp;
                    <Popup linkText="click here">
                        <h4 className='white'>Joint Account Details:</h4>
                        <div style={spanStyleNoTopBorder}>Sort Code: <span id="jointAcc_sortCode">04-00-04</span>&nbsp;&nbsp;
                            <CopyToClipboard title='Copy Sort Code'
                                             CopyToClipboardContentElementID='jointAcc_sortCode'
                                             float={"right"}/>
                        </div>
                        <div style={spanStyle}>Account Number: <span id="jointAcc_AccNum">18061339</span>&nbsp;&nbsp;
                            <CopyToClipboard title='Copy Account Number'
                                             CopyToClipboardContentElementID='jointAcc_AccNum'/>
                        </div>
                        <div style={spanStyle}>Bank Name: <span id="jointAcc_bankName">Monzo</span>&nbsp;&nbsp;
                            <CopyToClipboard title='Copy Bank Name'
                                             CopyToClipboardContentElementID='jointAcc_bankName'
                                             float={"right"}/>
                        </div>
                        <div style={spanStyle}>BIC: <span id="jointAcc_BIC">MONZGB2L</span>&nbsp;&nbsp;
                            <CopyToClipboard title='Copy BIC'
                                             CopyToClipboardContentElementID='jointAcc_BIC'
                                             float={"right"}/>
                        </div>
                    </Popup>.
                    <br/><br/>
                    Or if you want to surprise us, please contact our parents.
                </p>
            </div>
        )
    }

    render() {
        return (
            <div id="Wedding" className="section-container">
                <h1>The Wedding</h1>
                <Map/>
                {this.renderVenue()}
                {this.renderTravel()}
                {this.renderAccommodation()}
                {this.renderSchedule()}
                {this.renderWeddingParty()}
                {this.renderRegistry()}
                <Playlist/>
            </div>
        );
    }
}

export default WeddingDetails;