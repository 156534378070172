import React, {Component} from 'react';
import './Countdown.css';

class Countdown extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cdDays: '',
            cdHours: '',
            cdMins: '',
            cdDaysTxt: '',
            cdHoursTxt: '',
            cdMinsTxt: ''
        }
    }

    componentDidMount() {
        this.timerID = setInterval(() =>  this.tick(), 1000);
    }

    componentWillUnmount() {
        clearInterval(this.timerID);
    }

    tick() {
        const second = 1000,
            minute = second * 60,
            hour = minute * 60,
            day = hour * 24,
            wedding = new Date("Jul 8, 2022 17:00:00").getTime();

        let timeLeft = wedding - (new Date().getTime()),
            cd_days = Math.floor(timeLeft / (day)),
            cd_hours = Math.floor((timeLeft % (day)) / (hour)),
            cd_mins = Math.floor((timeLeft % (hour)) / (minute));
            //cd_secs = Math.floor((timeLeft % (minute)) / second);

        this.setState ({
            cdDays: cd_days.toString(),
            cdHours: cd_hours.toString(),
            cdMins: cd_mins.toString(),
            cdDaysTxt: ((cd_days !== 1) ? 'days' : 'day'),
            cdHoursTxt: ((cd_hours !== 1) ? 'hours' : 'hour'),
            cdMinsTxt: ((cd_mins !== 1) ? 'minutes' : 'minute')
        })

    }

    render() {
        return (
            <div className="section-container pink">
                <div id="cdTimer" className="countdown">
                    <ul>
                        <li>
                            <span id="days" className="num">{this.state.cdDays}</span><br/>
                            <span id="days_txt" className="txt">{this.state.cdDaysTxt}</span>
                        </li>
                        <li>
                            <span id="hours" className="num">{this.state.cdHours}</span><br/>
                            <span id="hours_txt" className="txt">{this.state.cdHoursTxt}</span>
                        </li>
                        <li>
                            <span id="mins" className="num">{this.state.cdMins}</span><br/>
                            <span id="mins_txt" className="txt">{this.state.cdMinsTxt}</span>
                        </li>
                    </ul>
                </div>
                <div id="cdParty" style={{display: "none"}}>
                    <h1>Time to Party</h1>
                </div>
            </div>
        );
    }
}

export default Countdown;
