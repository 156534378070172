import React, {Component} from 'react';
import PropTypes from 'prop-types';

class Polaroid extends Component {
    static defaultProps = {
        tilt: '',
        location: '',
        imgSrc: '',
        imgAlt: '',
        clickToEnlargeModalId: ''
    }

    constructor(props) {
        super(props);
        this.state = {
            imgSrc: "/assets/images/" + this.props.imgSrc,
            imgAlt: this.props.imgAlt,
            enlargeModalId: this.props.clickToEnlargeModalId
        }
    }

    enlargeImage() {
        if (this.state.enlargeModalId.length > 0 && document.getElementById(this.state.enlargeModalId) !== null) {
            // Open the Image Modal
            document.getElementById(this.state.enlargeModalId).style.display = "block";
            document.getElementById("modalImg").src = this.state.imgSrc ;
            document.getElementById("modalImg").alt = this.state.imgAlt ;
        }
    }

    render() {
        // Properties
        let _tilt = '', _loc = '';

        switch (this.props.tilt.toString().trim().toLowerCase()) {
            case "left": _tilt = ' rotate-left'; break;
            case "right": _tilt = ' rotate-right'; break;
            default: _tilt = '';
        }

        switch (this.props.location.toString().trim()) {
            case "center": _loc = ' center'; break;
            case "topRight": _loc = ' section-top-right'; break;
            case "topLeft": _loc = ' section-top-left'; break;
            case "bottomRight": _loc = ' section-bottom-right'; break;
            case "bottomLeft": _loc = ' section-bottom-left'; break;
            default: _loc = '';
        }

        const _enlargeable = this.state.enlargeModalId.length > 0 ? " enlargeable" : "";

        const _className = 'polaroid' + _tilt + _loc + _enlargeable;

        return (
            <img className={_className} src={this.state.imgSrc} alt={this.state.imgAlt}
                 onClick={() => this.enlargeImage()} />
        );
    }
}

Polaroid.propTypes = {
    tilt: PropTypes.oneOf(['', 'left', 'right']) ,
    location: PropTypes.oneOf(['', 'center', 'topRight', 'topLeft', 'bottomRight', 'bottomLeft']),
    imgSrc: PropTypes.string.isRequired,
    imgAlt: PropTypes.string,
    clickToEnlargeModalId: PropTypes.string,
}

export default Polaroid;