import React, {Component} from 'react';
import './App.css';
import sha from 'crypto-js/sha1';
import Home from './pages/home/Home';
import Login from './pages/login/Login';
import Modal from "./pages/home/components/Modal";

class App extends Component {

  render() {

    let _pwd = sessionStorage.getItem("pwd"),
        _pwdCheck = '2685d20a0e6a78c7f666830263cf6fae5a0943b0';

    let content = <Login /> ,
        addModals = false;
    if (sha(_pwd).toString() === _pwdCheck) {
        content = <Home />;
        addModals = true;
    }

    return (
        <div className="main-wrapper">
          <div className="main-container">
            { content }
          </div>
            { addModals && <Modal type="FAQ" /> }
            { addModals && <Modal type="ImageExpand" /> }
            { addModals && <Modal type="Gallery" /> }
        </div>
    );
  }
}

export default App;
