import React, {Component} from 'react';
import './Home.css';
import Countdown from './components/Countdown';
import Nav from './components/Nav';
import OurStory from "./components/OurStory";
import WeddingDetails from "./components/WeddingDetails";
import RSVP from "./components/FormRSVP";

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {value: '' }
    }

    goToSection(sectionId) {
        document.getElementById(sectionId).scrollIntoView({behavior: "smooth"});
    }

    Title() {
        return (
            <div className="title-container">
                <div className="title-content">
                    <h1 className="title">Michele &amp; James</h1>
                    <h2>Are Getting Married</h2>
                    <p>8<sup>th</sup> July 2022 in Tuscany, Italy</p>
                </div>
                <div className="title-pic"/>
            </div>
        )
    }

    WelcomeMsg() {
        return (
            <div id="WelcomeMsg" className="section-content">
                <p>Welcome to our wedding website, we can't wait to celebrate our special day with you.</p>
                <p>We created this website as a helpful resource for all of the need-to-know details in the lead up to
                    our big day. You'll find our schedule of timings, venue details, travel tips and things to do in the
                    local area.</p>
                <p>You can also read more about our story and get to know our wedding party and wedding planners.</p>
                <p>Have fun, take a look around and don't forget to&nbsp;
                    <span className="link" onClick={() => this.goToSection('rsvp')}>RSVP</span> and leave us a&nbsp;
                    <span className="link" onClick={() => this.goToSection('Playlist')}>song request</span> for the dance floor.</p>
                <p>Finally - Thank you for your ongoing love and support. We are so excited to share this day with
                    you and look forward to dancing the night away with all of our favourite people!</p>
            </div>
        )
    }

    RsvpContact() {
        return (
            <div className="section-container">
                <RSVP/>
                <div id="ContactUs" className="section-content">
                    <h1>Contact Us</h1>
                    <p>If you have any questions please&nbsp;
                        <a href="mailto:jim.draycott@googlemail.com; michelecaneppele@hotmail.com?subject=Wedding">
                            contact us
                        </a>
                    </p>
                </div>
            </div>
        )
    }

    render() {
        return (
            <div className="main-content">
                {this.Title()}
                {this.WelcomeMsg()}
                <Countdown/>
                <Nav/>
                <OurStory/>
                <div className="picture-break tuscany"/>
                <WeddingDetails/>
                <div className="picture-break tuscany2"/>
                {this.RsvpContact()}
                <div className="footer"/>
            </div>
        );
    }
}

export default Home;