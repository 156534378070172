import React, {Component} from "react";
import './Map.css';

//let _mapHeight = 0;

class WeddingDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            planeTopPos : 0,
            planeLeftPos : 0,
            planeRotate: 130,
            scrollTop: 0,
            mapHeight: 0,
            mapTop: 0
        }
    }

    componentDidMount() {
        //console.log('componentDidMount');
        document.addEventListener('scroll', this.handleScroll, true);

        const _map = document.getElementById('map');
        this.setState({
            mapHeight: _map.offsetHeight,
            mapTop: _map.getBoundingClientRect().top
        });

    }

    componentWillUnmount() {
        //console.log('componentWillUnmount');
        document.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = (e) => {
        //console.log("Scrolling!");
        // let _scrollTop = e.target.scrollTop,
        //      _map = document.getElementById('map'),
        //     _mapTop =  _map.getBoundingClientRect().top,
        //     _mapHeight = _map.offsetHeight

        //console.log(_scrollTop + ' - ' + _mapTop + ' = ' + (_scrollTop - _mapTop));
        //console.log(_mapHeight);


        // const scrollTop = this.myRef.current.scrollTop
        // console.log(`onScroll, myRef.scrollTop: ${scrollTop}`)
        // this.setState({ scrollTop: scrollTop })
    }

    render() {
        // const _state = this.state;
        //console.log('mapHeight: ' + _state.mapHeight);


        // let planeStyle = {
        //     top: _state.planeTopPos,
        //     left: _state.planeLeftPos,
        //     transform: "rotate(" + _state.planeRotate + "deg)"
        // }

        return (
            <div id="map" className="map-container">
                <img className="map" src="/assets/images/Italy.svg" alt='Map of Italy'/>
                {/*<img className="plane" src="/assets/images/airplane.png" alt='Airplane flying to the location'/>*/}
            </div>
        )
    }
}

export default WeddingDetails;