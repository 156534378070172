import React, {Component} from 'react';
import Polaroid from "./Polaroid";

class OurStory extends Component {

    renderGrowingUp() {
        return (
            <div className="section-container">
                <h2 id="GrowingUp">Growing Up</h2>
                <div className="section-group">
                    <div className="section-content">
                        <div className="michele-pic circle-pic"/>
                        <h3>Michele</h3>
                        <p>Michele is an Aussie/Brit hybrid, born in the UK and from 11 years old called Perth,
                            Australia, home.</p>
                        <p>Her uni years were mostly spent shopping, at the beach or in one of Perth's many watering
                            holes. In 2008, after 5.5 years at uni, she finally graduated and decided to purchase an
                            around the world ticket with stops in South America, Europe and Singapore.</p>
                        <p>The last leg of the ticket was not used (much to her mother’s despair) and so since 2009
                            Michele has been based in the UK. Her adventures have been funded by a career in
                            medical research starting within academia before moving into the pharmaceutical and
                            biotechnology fields.</p>
                    </div>
                    <div className="section-content">
                        <div className="jim-pic circle-pic"/>
                        <h3>James</h3>
                        <p>James was born and raised on the south coast of England, in Lymington, located between
                            the sea and the New Forest national park.</p>
                        <p>Growing up, James spent a lot of time on the water sailing, both racing and pottering
                            around on the family boat, in the countryside cycling, camping or propping up at a
                            countryside pub with a pint of bitter in hand.</p>
                        <p>James moved up to London to see what the big smoke had to offer in the summer of 2012,
                            during the craziness of the Olympics, which provided a great introduction to big city
                            living. A career in FinTech has paid for all of the socialising and seeking out the
                            next hidden speakeasy.</p>
                    </div>
                </div>
                <div className="section-content">
                    <div className="hank-pic circle-pic"/>
                    <h3>Hank</h3>
                    <p>We now live together with our fur baby, Hank
                        (<a href="https://www.instagram.com/hunkyhank_thedoxie/" target="_blank"  rel="noreferrer">
                            #hunkyhank_thedoxie</a>) in our little flat in Brixton, London.</p>
                </div>
            </div>
        )
    }

    renderFirstDate() {
        return (
            <div id="FirstDate" className="section-content">
                <h2>First Date</h2>
                <p>After initially meeting through the dating app, Inner Circle, we quickly set up a date doing
                    something that we both have a passion for - Drinking Wine...</p>
                <p>So on the 11<sup>th</sup> May 2017 we met at the The Remedy Wine Bar in Fitzrovia, London. After
                    tasting the majority of the “available by the glass” menu we made a unanimous decision not to
                    drink orange wine again.</p>
                <p><i>Note: there will be no orange wine served during any of our wedding events.</i></p>
                <div className="polaroid-group">
                    <Polaroid imgSrc={'bottles.jpeg'} imgAlt={'Wine Bottles'}
                              tilt={'left'} clickToEnlargeModalId={"imgModal"} />
                    <Polaroid imgSrc={'WineGlasses6.jpg'} imgAlt={'Wine Glasses'}
                              tilt={'right'} clickToEnlargeModalId={"imgModal"} />
                </div>
            </div>
        )
    }

    renderProposal() {
        return (
            <div id="Proposal" className="section-content">
                <h2>The Proposal</h2>
                <p>We went on a road trip to the Lake District, via the Cotswolds and Peak District, to celebrate
                    our
                    birthdays. On the 15<sup>th</sup> October we headed out on a walk around Grasmere lake and
                    Rydal Water...</p>
                <div className="polaroid-group">
                    <Polaroid imgSrc={'grasemere1.jpg'} imgAlt={'Grasemere Lake'}
                              tilt={"left"} clickToEnlargeModalId={"imgModal"} />
                    <Polaroid imgSrc={'grasemere2.jpg'} imgAlt={'Grasemere Lake'}
                              clickToEnlargeModalId={"imgModal"} />
                    <Polaroid imgSrc={'HankInGrasmere.jpeg'} imgAlt={'Hank looking over Grasemere Lake'}
                              tilt={"right"} clickToEnlargeModalId={"imgModal"} />
                </div>
                <p>We came across a nice little beach on the lakeside, perfect to take some photos and for
                    Hank to sniff out all the smells available.</p>
                <p>On that lakeside beach in the sunshine, James dropped down onto one knee and asked Michele,
                    to marry him.</p>
                <div className="polaroid-group">
                    <Polaroid imgSrc={'Couple2.jpg'} imgAlt={'Michele and James'}
                              tilt={'left'} clickToEnlargeModalId={"imgModal"} />
                    <Polaroid imgSrc={'ringBubbles.jpg'} imgAlt={'The ring'}
                              tilt={'right'} clickToEnlargeModalId={"imgModal"} />
                </div>
            </div>
        )
    }

    render() {
        return (
            <div id="OurStory" className="section-container">
                <h1>Our Story</h1>
                {this.renderGrowingUp()}
                {this.renderFirstDate()}
                {this.renderProposal()}
            </div>
        );
    }
}

export default OurStory;