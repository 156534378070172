import React, {Component} from "react";
import './Modal.css';
import FAQ from './FAQ';
import Gallery from './Gallery';
import PropTypes from "prop-types";

class Modal extends Component {

    handleCloseModal() {
        let modalId;
        switch (this.props.type) {
            case "FAQ": modalId = 'faqModal'; break;
            case "Gallery": modalId = 'galleryModal'; break;
            case "ImageExpand": modalId = 'imgModal'; break;
            default: modalId = '';
        }

        document.getElementById(modalId).style.display = "none";
    }

    render() {
        let modalId, modalContent;
        switch (this.props.type) {
            case "FAQ":
                modalId = 'faqModal';
                modalContent = <FAQ/>;
                break;
            case "Gallery":
                modalId = 'galleryModal';
                modalContent = <Gallery/>;
                break;
            case "ImageExpand":
                modalId = 'imgModal';
                modalContent = <img className="modal-content" id="modalImg" src="" alt="" />;
                break;
            default:
                modalId = '';
                modalContent = '';
        }

        return (
            <div id={modalId} className="modal" >
                <span className="close-modal" onClick={() => this.handleCloseModal()}>&times;</span>
                <div className="modal-content">
                    {modalContent}
                </div>
            </div>
        );
    }
}

Modal.propTypes = {
    type: PropTypes.oneOf(['FAQ', 'Gallery', 'ImageExpand']).isRequired
}

export default Modal;