import React, {Component} from 'react';
import './Login.css';

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {value: '' }
        sessionStorage.clear();
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        this.setState({value: event.target.value});
    }

    handleSubmit(event) {
        let _pwd = this.state.value.toString().trim();
        if (_pwd.length > 0) {sessionStorage.pwd = _pwd;}
    }

    render() {
        return (
            <div className="login-container">
                <div className="login-pic"/>
                <div className="login-contents">
                    <h1>Michele &amp; James</h1>
                    <h2>Wedding Website</h2>
                </div>
                <form className="login-form" onSubmit={this.handleSubmit}>
                    <span>Password:</span>
                    <input type="text" autoFocus value={this.state.value} onChange={this.handleChange} />
                    <input type="submit" value="Enter" />
                </form>
            </div>
        );
    }
}

export default Login;