import React, {Component} from 'react';
import PropTypes from 'prop-types';
import './Popup.css';

class Popup extends Component {
    static defaultProps = {
        linkText: ''
    }

    constructor(props) {
        super(props);
        this.state = {
            linkText: props.linkText,
            content: props.children,
            show: ''
        }
    }

    handleShowPopup() {
        let _show = this.state.show;
        _show === "show"? _show = "" : _show = "show";
        this.setState({show: _show});
    }

    render() {
        return (
            <div className="popup">
                <span className="popupLink" onClick={() => this.handleShowPopup()}>{this.state.linkText}</span>
                <span className={"popupText " + this.state.show}>
                    <span className="popupClose" onClick={() => this.handleShowPopup()}>x</span>
                    {this.state.content}
                </span>
            </div>
        );
    }
}

Popup.propTypes = {
    linkText: PropTypes.string.isRequired
}

export default Popup;