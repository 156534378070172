export const galleryData = [
    {
        "title": "Overlooking Melbourne City during Jim's first Christmas in Aus ",
        "url": "/assets/images/Couple.jpg",
        "type": "image"
    },
    {
        "title": "View from our hotel room terrace overlooking Positano, Amalfi Coast, Italy",
        "url": "/assets/images/Couple1.jpg",
        "type": "image"
    },
    {
        "title": "Us out on a walk around Lake Grasemere in the Lake District",
        "url": "/assets/images/Couple2.jpg",
        "type": "image"
    },
    {
        "title": "Pre-take off bubbles on our direct flight to Perth for Christmas",
        "url": "/assets/images/Couple3.jpg",
        "type": "image"
    },
    {
        "title": "Rocking our beanies whilst walking Hank in the snow around a local park",
        "url": "/assets/images/Couple4.jpg",
        "type": "image"
    },
    {
        "title": "Finding a waterfall during our road-trip near Melbourne",
        "url": "/assets/images/Couple5.jpg",
        "type": "image"
    },
    {
        "title": "A classic 'Everyone ready for the Family photo??' during a walk in the Cotswolds.",
        "url": "/assets/images/Couple6.jpg",
        "type": "image"
    },
    {
        "title": "A day of 4 seasons, seeing the 12 Apostles along Great Ocean Road, near Melbourne",
        "url": "/assets/images/Couple7.jpg",
        "type": "image"
    },
    {
        "title": "A pouting booze cruise around Perth Harbour",
        "url": "/assets/images/Couple10.jpg",
        "type": "image"
    },
    {
        "title": "Finding a little gin drinking spot for some martini's during our spa weekend away in Bath.",
        "url": "/assets/images/Couple11.jpg",
        "type": "image"
    },
    {
        "title": "Family photo after walking up to the castle",
        "url": "/assets/images/Couple12.jpg",
        "type": "image"
    },
    {
        "title": "Overlooking the Roman Forum with the Colosseum in the background",
        "url": "/assets/images/Couple13.jpg",
        "type": "image"
    },
    {
        "title": "Having a cheeky cold beer in Little Creatures Brewery, Fremantle, Perth",
        "url": "/assets/images/Couple14.jpg",
        "type": "image"
    },
    {
        "title": "Looking pretty during our first easter dinner, professionally cooked by Michele",
        "url": "/assets/images/Couple15.jpg",
        "type": "image"
    },
    {
        "title": "Waiting for the Perth sunset",
        "url": "/assets/images/Couple18.jpeg",
        "type": "image"
    },
    {
        "title": "A Perth Sunset",
        "url": "/assets/images/Couple19.jpeg",
        "type": "image"
    },
    {
        "title": "Family photo during a winter walk in Greenwich Park, London",
        "url": "/assets/images/Couple21.jpeg",
        "type": "image"
    },
    {
        "title": "Visiting the Quokkas on Rottnest Island during James's first visit to Aus",
        "url": "/assets/images/CoupleQuaka.jpg",
        "type": "image"
    },
    {
        "title": "Day trip to Greenwich on a boat tour with Marta, Charlie and Rob",
        "url": "/assets/images/Friends.jpg",
        "type": "image"
    },
    {
        "title": "Jim and Hank enjoying a pint at a local brewery after a walk around the park.",
        "url": "/assets/images/JimHank.jpeg",
        "type": "image"
    },
    {
        "title": "Beer tasting at West Berkshire Brewery during a last minute weekend away.",
        "url": "/assets/images/MicheleJim.jpeg",
        "type": "image"
    },
    {
        "title": "A Sunday walk around Battersea Park.",
        "url": "/assets/images/Park.jpeg",
        "type": "image"
    }
];