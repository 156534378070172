import React, {Component} from 'react';
import {service} from "../../../actions/service";
import {common} from "../../../actions/common";

class FormRSVP extends Component {
    constructor(props) {
        super(props);
        this.state = {
            guestName: '',
            guestNames: [],
            currentTab: 'pizza',
            attendancePizza: '',
            attendanceCeremony: '',
            attendancePool: '',
            valChkAttendancePizza: true,
            valChkAttendanceCeremony: true,
            valChkAttendancePool: true,
            weddingMealMeat: [],
            weddingMealFish: [],
            weddingMealVeg: [],
            dietaryReqs: '',
            iceCreamSuggestion: '',
            stayOnSite: false,
            rsvpSubmitted: false
        }

        this.guestNameEnter = this.guestNameEnter.bind(this);
        this.toggleRdoState = this.toggleRdoState.bind(this);
        this.handleRSVPInputChange = this.handleRSVPInputChange.bind(this);
        this.handleRSVPSubmit = this.handleRSVPSubmit.bind(this);
    }

    toggleRdoState(e) {
        const target = e.target,
            value = target.value,
            name = target.name;
        this.setState({ [name]: value });

        if (name === 'attendanceCeremony' && value === 'No') {
            this.setState({
                weddingMealMeat: [],
                weddingMealFish: [],
                weddingMealVeg: [],
                dietaryReqs: ''
            })
        }

        switch (name) {
            case 'attendancePizza' :
                if (value !== '') {this.setState({valChkAttendancePizza: true});}
                break;
            case 'attendanceCeremony' :
                if (value !== '') {this.setState({valChkAttendanceCeremony: true});}
                break;
            case 'attendancePool' :
                if (value !== '') {this.setState({valChkAttendancePool: true});}
                break;
            default : break;
        }
    }

    handleRSVPTabChange(tab) {
        this.setState({currentTab: tab});
    }

    handleRSVPInputChange(e) {
        const target = e.target,
            value = target.type === 'checkbox' ? target.checked : target.value,
            name = target.name;

        this.setState({[name]: value});
    }

    guestNameEnter(e) {
        if ( e.charCode === 13 ) { this.handleAddGuestNames(); }
    }

    handleAddGuestNames() {
        let _guestNames = this.state.guestNames,
            _guest = common.capitaliseString(this.state.guestName);
        if (_guestNames.indexOf(_guest) < 0) {_guestNames.push(_guest);}
        this.setState({guestNames: _guestNames, guestName: ''});
    }

    handleRemoveGuestName(guest) {
        let _guestNames = this.state.guestNames,
            _weddingMealMeat = this.state.weddingMealMeat,
            _weddingMealFish = this.state.weddingMealFish,
            _weddingMealVeg = this.state.weddingMealVeg;

        if (_guestNames.indexOf(guest) >= 0) {
            _guestNames.splice(_guestNames.indexOf(guest), 1);
            // clear any previous choice
            this.removeFromArray(_weddingMealMeat, guest);
            this.removeFromArray(_weddingMealFish, guest);
            this.removeFromArray(_weddingMealVeg, guest);
        }

        if (_guestNames.length === 0) {
            this.setState({attendancePizza: '', attendanceCeremony: '', attendancePool: '', currentTab: 'pizza'})
        }

        this.setState(
            {
                guestNames: _guestNames,
                weddingMealMeat: _weddingMealMeat,
                weddingMealFish: _weddingMealFish,
                weddingMealVeg: _weddingMealVeg
            });
    }

    handleWeddingMeal(meal, guest) {
        let _weddingMealMeat = this.state.weddingMealMeat,
            _weddingMealFish = this.state.weddingMealFish,
            _weddingMealVeg = this.state.weddingMealVeg;

        // clear any previous choice
        this.removeFromArray(_weddingMealMeat, guest);
        this.removeFromArray(_weddingMealFish, guest);
        this.removeFromArray(_weddingMealVeg, guest);

        // Record meal choice
        switch (meal) {
            case "Meat": _weddingMealMeat.push(guest); break;
            case "Fish": _weddingMealFish.push(guest); break;
            case "Veg": _weddingMealVeg.push(guest); break;
            default: break;
        }

        this.setState({
            weddingMealMeat: _weddingMealMeat,
            weddingMealFish: _weddingMealFish,
            weddingMealVeg: _weddingMealVeg
        });
    }

    removeFromArray(arr = [], val) {
        let _arr = arr;
        if (_arr.indexOf(val) >= 0) {_arr.splice(_arr.indexOf(val), 1)}
        return _arr;
    }

    handleRSVPSubmit(e) {
        let _valCheck = true, _valCheckMessage = 'Whoops you missed something from your RSVP. \n\nPlease let us know the following: \n';

        // check attendance to all events.
        if (this.state.attendancePizza === '' ) {
            _valCheck = false;
            _valCheckMessage += ' - Attendance to the Pizza party. \n';
            this.setState({valChkAttendancePizza: false});
        }
        if (this.state.attendanceCeremony === '' ) {
            _valCheck = false;
            _valCheckMessage += ' - Attendance to the Ceremony. \n';
            this.setState({valChkAttendanceCeremony: false});
        }
        if (this.state.attendancePool === '') {
            _valCheck = false;
            _valCheckMessage += ' - Attendance to the Pool party. \n';
            this.setState({valChkAttendancePool: false});
        }

        // check meal selection
        if (this.state.attendanceCeremony === 'Yes') {
            const _state = this.state,
                _guestCount = _state.guestNames.length,
                _mealCount = _state.weddingMealMeat.length + _state.weddingMealFish.length + _state.weddingMealVeg.length;
            if (_guestCount !== _mealCount) {
                _valCheck = false;
                _valCheckMessage += ' - Missing meal choice for the Ceremony. \n';
                this.setState({valChkAttendanceCeremony: false});
            }
        }

        if (_valCheck) {

            // Passes the validation checks and submits the RSVP
            const _rsvpSubmitted = service.saveRSVP(this.state.guestNames,
                 this.state.attendancePizza,
                 this.state.attendanceCeremony,
                 this.state.attendancePool,
                 this.state.weddingMealMeat,
                 this.state.weddingMealFish,
                 this.state.weddingMealVeg,
                 this.state.dietaryReqs,
                 this.state.iceCreamSuggestion,
                 this.state.stayOnSite ? "Yes" : "No")

            this.setState({rsvpSubmitted: _rsvpSubmitted})
        } else {
            // Failed the validation checks and show the alert
            window.alert(_valCheckMessage);
        }
        e.preventDefault();
    }

    goToWeddingSchedule() {
        document.getElementById('Schedule').scrollIntoView({behavior: "smooth"});
    }

    renderGuestForm() {
        const _guestNames = this.state.guestNames;
        return (
            <div className="item">
                <span>Guest Name(s):</span>
                <div className="guestNameInputContainer">
                    <input type="text" name="guestName" value={this.state.guestName}
                           onChange={this.handleRSVPInputChange} autoComplete="off"
                           placeholder="Please enter a Guest Name and click the Add button"
                           onKeyPress={this.guestNameEnter} />
                    <button type="button" onClick={() => {this.handleAddGuestNames()}} >Add</button>
                </div>
                {_guestNames.length > 0 ?
                <div className="guestList">
                    {_guestNames.map((guest) =>
                        <div className="guest" onClick={() => {this.handleRemoveGuestName(guest)}}
                             title="Click to Remove">
                            {guest}
                        </div>)
                    }
                </div> : '' }
            </div>
        )
    }

    renderAttendanceForm() {
        let tabStylePizza = "tab-item",
            tabStyleCeremony = "tab-item",
            tabStylePost = "tab-item",
            tabContentStylePizza = { display: "none"},
            tabContentStyleCeremony = { display: "none"},
            tabContentStylePost = { display: "none"},
            _guestNum = this.state.guestNames.length;

        switch (this.state.currentTab.toString()) {
            case 'pizza' : tabContentStylePizza = { display: "block"}; tabStylePizza += " active"; break;
            case 'ceremony' : tabContentStyleCeremony = { display: "block"}; tabStyleCeremony += " active"; break;
            case 'post' : tabContentStylePost = { display: "block"}; tabStylePost += " active"; break;
            default : tabContentStylePizza = { display: "block"}; tabStylePizza += " active";
        }

        return (
            <div className="tabs">
                <span>Attendance to:</span>
                <div className="tab-bar">
                    <div className={tabStylePizza} onClick={() => {
                        this.handleRSVPTabChange('pizza')
                    }}>
                        Pre Wedding Pizza Party
                        {!this.state.valChkAttendancePizza && <span> * </span> }
                    </div>
                    <div className={tabStyleCeremony} onClick={() => {
                        this.handleRSVPTabChange('ceremony')
                    }}>
                        Wedding Ceremony
                        {!this.state.valChkAttendanceCeremony && <span> * </span> }
                    </div>
                    <div className={tabStylePost} onClick={() => {
                        this.handleRSVPTabChange('post')
                    }}>
                        Post Wedding Pool Day
                        {!this.state.valChkAttendancePool && <span> *  </span> }
                    </div>
                </div>

                <div className="tab-content" style={tabContentStylePizza}>
                    <div className="infoBtn" onClick={() => this.goToWeddingSchedule()}
                         title="Additional Information"/>
                    <h3>Pizza Party (Thurs 7<sup>th</sup> July)</h3>
                    <div className="rdoContainer">
                        <div className="rdoItem">
                            <input type="radio" id="rdoAttendancePizzaYes" name="attendancePizza"
                                   value="Yes"
                                   onChange={this.toggleRdoState}
                                   checked={this.state.attendancePizza === 'Yes'}/>
                            <label htmlFor="rdoAttendancePizzaYes">
                                Yeah, {_guestNum > 1 ? "Feed us" : "Gimme"} that Pizza...
                            </label>
                        </div>
                        <div className="rdoItem">
                            <input type="radio" id="rdoAttendancePizzaNo" name="attendancePizza" value="No"
                                   onChange={this.toggleRdoState}
                                   checked={this.state.attendancePizza === 'No'}/>
                            <label htmlFor="rdoAttendancePizzaNo">
                                Nah, {_guestNum > 1 ? "We'll" : "I'll"} skip it thanks.</label>
                        </div>
                    </div>
                </div>

                <div className="tab-content" style={tabContentStyleCeremony}>
                    <div className="infoBtn" onClick={() => this.goToWeddingSchedule()}
                         title="Additional Information"/>
                    <h3>Wedding Ceremony (Fri 8<sup>th</sup> July)</h3>
                    <div className="rdoContainer">
                        <div className="rdoItem">
                            <input type="radio" id="rdoAttendanceCeremonyYes" name="attendanceCeremony"
                                   value="Yes"
                                   onChange={this.toggleRdoState}
                                   checked={this.state.attendanceCeremony === 'Yes'}/>
                            <label htmlFor="rdoAttendanceCeremonyYes">
                                Yes, {_guestNum > 1 ? "Our bags are" : "My bag is"} packed, can't wait.
                            </label>
                        </div>
                        <div className="rdoItem">
                            <input type="radio" id="rdoAttendanceCeremonyNo" name="attendanceCeremony"
                                   value="No"
                                   onChange={this.toggleRdoState}
                                   checked={this.state.attendanceCeremony === 'No'}/>
                            <label htmlFor="rdoAttendanceCeremonyNo">
                                No, but {_guestNum > 1 ? "we'll" : "I'll"} raise a glass from afar.
                            </label>
                        </div>
                    </div>
                    {this.state.attendanceCeremony === 'Yes' ?
                        <div>
                            <hr/>
                            <p className="rdoTitle">For the main course</p>
                            <div className="grid">
                                {this.state.guestNames.map((guest) =>
                                    <div className="gridRow">
                                        <div className="gridCell cw3 textRight">
                                            <span className="guestName">{guest}:</span>
                                        </div>
                                        <div className="gridCell cw7">
                                            <div className="rdoContainer">
                                                <div className="rdoItem">
                                                    <input type="radio" id={'rdoWeddingMealMeat' + guest}
                                                           name={'weddingMeal' + guest}
                                                           onChange={() => this.handleWeddingMeal('Meat', guest)}
                                                           checked={this.state.weddingMealMeat.indexOf(guest) >= 0}/>
                                                    <label htmlFor={'rdoWeddingMealMeat' + guest}>Florentine
                                                        Steak</label>
                                                </div>
                                                <div className="rdoItem">
                                                    <input type="radio" id={'rdoWeddingMealFish' + guest}
                                                           name={'weddingMeal' + guest}
                                                           onChange={() => this.handleWeddingMeal('Fish', guest)}
                                                           checked={this.state.weddingMealFish.indexOf(guest) >= 0}/>
                                                    <label htmlFor={'rdoWeddingMealFish' + guest}>Herbed Steamed Sea
                                                        Bass</label>
                                                </div>
                                                <div className="rdoItem">
                                                    <input type="radio" id={'rdoWeddingMealVeg' + guest}
                                                           name={'weddingMeal' + guest}
                                                           onChange={() => this.handleWeddingMeal('Veg', guest)}
                                                           checked={this.state.weddingMealVeg.indexOf(guest) >= 0}/>
                                                    <label htmlFor={'rdoWeddingMealVeg' + guest}>Vegetarian</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="item">
                                <span>Special Dietary Requirements:</span>
                                <input type="text" name="dietaryReqs" value={this.state.dietaryReqs}
                                       onChange={this.handleRSVPInputChange}/>
                            </div>
                        </div> : ''
                    }
                </div>

                <div className="tab-content" style={tabContentStylePost}>
                    <div className="infoBtn" onClick={() => this.goToWeddingSchedule()}
                         title="Additional Information"/>
                    <h3>Post Wedding Pool Day (Sat 9<sup>th</sup> July):</h3>
                    <div className="rdoContainer">
                        <div className="rdoItem">
                            <input type="radio" id="rdoAttendancePostYes" name="attendancePool" value="Yes"
                                   onChange={this.toggleRdoState}
                                   checked={this.state.attendancePool === 'Yes'}/>
                            <label htmlFor="rdoAttendancePostYes">
                                Yes, {_guestNum > 1 ? "We" : "I"} would love some R&amp;R by the pool.
                            </label>
                        </div>
                        <div className="rdoItem">
                            <input type="radio" id="rdoAttendancePostNo" name="attendancePool" value="No"
                                   onChange={this.toggleRdoState}
                                   checked={this.state.attendancePool === 'No'}/>
                            <label htmlFor="rdoAttendancePostNo">
                                No thanks, {_guestNum > 1 ? "We" : "I"} have other plans.
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    renderGelatoForm() {
        return (
            <div className="item">
                <span>What is your favourite Gelato flavour?</span>
                <div className="item-content">
                    <input type="text" name="iceCreamSuggestion" value={this.state.iceCreamSuggestion}
                           onChange={this.handleRSVPInputChange}/><br/>
                    <span className="small">
                        (E.g Chocolate, Amarena Cherry, Chocolate-Hazelnut, Lemon, Pistachio,
                        Hazelnut, Fior di Latte(milk), Seasonal Fruit, Strawberry, Stracciatella (choc-chip) )
                    </span>
                </div>
            </div>
        )
    }

    renderStayOnSiteForm() {
        return (
            <div className="item">
                <span className="top">Would you like to stay on site?</span>
                <input name="stayOnSite" type="checkbox" value={this.state.stayOnSite}
                       onChange={this.handleRSVPInputChange} />
                <label htmlFor="chkStayOnsite">
                    Yes, <span className="small">if available I / we would like to stay on-site accommodation.</span>
                </label>
            </div>
        )
    }

    renderSubmitBtn() {
        return (
            <button id="btnRSVPSubmit" onClick={this.handleRSVPSubmit} >
                {this.state.rsvpSubmitted ? 'Submitted' : 'RSVP'}
            </button>
        )
    }

    renderRsvpSubmitted() {
        return (
            <h3>Submitted...</h3>
        )
    }

    render() {
        const _guestNum = this.state.guestNames.length;
        return (
            <div id="rsvp" className="section-content">
                <h1>RSVP</h1>
                {this.state.rsvpSubmitted ? this.renderRsvpSubmitted() :
                <div className="form">
                    {this.renderGuestForm()}
                    {_guestNum > 0 ? this.renderAttendanceForm() : ''}
                    {_guestNum > 0 ? this.renderGelatoForm() : ''}
                    {_guestNum > 0 ? this.renderStayOnSiteForm() : ''}
                    {_guestNum > 0 ? this.renderSubmitBtn() : ''}
                </div>
                }
            </div>
        );
    }
}

export default FormRSVP;