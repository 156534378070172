import React, {Component} from "react";
import './FAQ.css';

class FAQ extends Component {

    goToSection(sectionId) {
        document.getElementById('faqModal').style.display = "none";
        document.getElementById(sectionId).scrollIntoView({behavior: "smooth"});
    }

    handleItemClick = (e) => {
        e.target.classList.toggle('active');
        let faqItemContent = e.target.nextElementSibling;
        if (faqItemContent.style.maxHeight) {
            faqItemContent.style.maxHeight = null;
        } else {
            faqItemContent.style.maxHeight = faqItemContent.scrollHeight + "px";
        }
    }

    render() {
        return (
            <div id="faq">
                <h2>Q &amp; A</h2>
                <button className="faq-item" onClick={this.handleItemClick} >
                    - What should I wear? Is there a dress code? -
                </button>
                <div className="faq-item-content">
                    <p>
                        We hope our wedding will be a fun excuse to wear your favourite cocktail attire! The ceremony
                        and cocktail party will on grass/uneven ground so we advise wearing appropriate footwear
                        (maybe not those 6” stilettos although they can be brought out for the after dinner party!).
                    </p>
                </div>
                <button className="faq-item" onClick={this.handleItemClick} >
                    - What will the weather be like? -
                </button>
                <div className="faq-item-content">
                    <p>
                        Imagine driving through fields of sunflowers on a hot, sunny, cloudless day. At night the moon
                        comes out and crickets chirp. <br/>
                        Fingers crossed this happens for us!
                    </p>
                </div>
                <button className="faq-item"  onClick={this.handleItemClick}>
                    - Where are the ceremony and reception taking place? -
                </button>
                <div className="faq-item-content">
                    <p>
                        Both are at Casa Cornacchi.
                    </p>
                </div>
                <button className="faq-item"  onClick={this.handleItemClick}>
                    - What time should I / we arrive? -
                </button>
                <div className="faq-item-content">
                    <p>
                        The ceremony will begin at 5:30 pm on July 8<sup>th</sup>. We kindly ask guests to arrive
                        around 5 pm. While Michele tends to be late for things she will be getting ready at
                        the venue so has no excuse for being late!
                    </p>
                </div>
                <button className="faq-item" onClick={this.handleItemClick} >
                    - Can I take pictures during the wedding? -
                </button>
                <div className="faq-item-content">
                    <p>
                        We have a fantastic photographer so we ask you to refrain from taking photos during the
                        ceremony. <br/>
                        Outside the ceremony please feel free to take as many photos as you like. <br/>
                        Please use the hashtag #michelewedsjames so we can find the snaps you have taken!
                    </p>
                </div>
                <button className="faq-item" onClick={this.handleItemClick} >
                    - Will parking be available? -
                </button>
                <div className="faq-item-content">
                    <p>
                        Yes, free of charge at the venue.
                    </p>
                </div>
                <button className="faq-item" onClick={this.handleItemClick} >
                    - If I’m not staying at the venue will taxis be provided? -
                </button>
                <div className="faq-item-content">
                    <p>
                        Yes, but please let us know in advance if you will need one so our lovely wedding planners,
                        Hannah and Mari, can arrange.
                    </p>
                </div>
                <button className="faq-item" onClick={this.handleItemClick} >
                    - I / We have dietary restrictions? -
                </button>
                <div className="faq-item-content">
                    <p>
                        Not a problem, our venue can accommodate for most dietary types: vegetarian, vegan, lactose
                        free, gluten free, nut free, etc. Please let us know if you have any dietary requirements when
                        you RSVP.<br/>
                        All food will be prepared by cooks local to Tuscany using, where possible, local ingredients or
                        produce directly from Casa Cornacchi’s farm.
                    </p>
                </div>
                <button className="faq-item" onClick={this.handleItemClick} >
                    - Can I bring a date? -
                </button>
                <div className="faq-item-content">
                    <p>
                        Ooo la la! Whilst we’d love to have your guests attend, we do have a restricted guest count
                        and can only accommodate those guests formally invited on your wedding invitation.
                    </p>
                </div>
                <button className="faq-item" onClick={this.handleItemClick} >
                    - Are children welcome? -
                </button>
                <div className="faq-item-content">
                    <p>
                        Unfortunately our restricted guest count means that we cannot have children at the ceremony
                        or dinner. We are providing an on-site babysitting service so children are allowed at the
                        venue and will be able dance the night away with us at the after dinner party. Please note we
                        can provide your child's dinner at an additional cost (tbd).
                    </p>
                </div>
                <button className="faq-item" onClick={this.handleItemClick} >
                    - What is the RSVP deadline? -
                </button>
                <div className="faq-item-content">
                    <p>
                        We would love to know if you can or cannot make it (we hope you can!).<br/>
                        Please RSVP by February 1 <sup>st</sup> 2022 via our RSVP section&nbsp;
                        <span className="link" onClick={() => this.goToSection('rsvp')}>here</span>.
                    </p>
                </div>
                <button className="faq-item" onClick={this.handleItemClick} >
                    - Have you booked the entire villa? -
                </button>
                <div className="faq-item-content">
                    <p>
                        Yes! We’ve booked Casa Cornacchi Country House from the 7<sup>th</sup> to 10<sup>th</sup> July.<br/>
                        It sleeps 38 people. <br/>
                        This includes daily continental and English buffet breakfast and access to the venue’s gardens
                        and facilities. Also each suite is self contained and has a full kitchen.<br/>
                        This is initially being offered to family and the wedding party. <br/>
                        If you’re interested in staying at the venue please note when RSVPing.
                    </p>
                </div>
                <button className="faq-item" onClick={this.handleItemClick} >
                    - I / We are not planning to stay on-site, is there somewhere local we can stay? -
                </button>
                <div className="faq-item-content">
                    <p>
                        There are plenty of options in the local area. Please get in touch with our wedding planners,
                        Hannah and Mari, who will be able to assist with booking accommodation.
                    </p>
                </div>
                <button className="faq-item" onClick={this.handleItemClick} >
                    - Are there other wedding events I’m / we’re invited to? -
                </button>
                <div className="faq-item-content">
                    <p>
                        Everyone is welcome to our pizza party in the evening on July 7<sup>th</sup> and pool day /
                        BBQ lunch on July 9<sup>th</sup>. Both will be held at Casa Cornacchi.<br/>
                        If you're interested in attending either / both of the additional events, please select the
                        options in the RSVP.
                    </p>
                </div>
                <button className="faq-item" onClick={this.handleItemClick} >
                    - If there is an issue during the event who should I raise it with? -
                </button>
                <div className="faq-item-content">
                    <p>
                        Please speak to our wedding planners, Hannah and Mari, as they are there to ensure the
                        events happen smoothly. <br/>
                        If Hannah or Mari are unavailable, please seek out someone from the wedding party.
                    </p>
                </div>
                <button className="faq-item" onClick={this.handleItemClick} >
                    - What can I do in the local area? -
                </button>
                <div className="faq-item-content">
                    <p>
                        Casa Cornacchi is located within the triangle of Florence, Sienna and Arezzo amongst
                        rolling green hills. The Chianti wine region lies to the north and the Montepulciano region
                        to the south, all are accessible for day trips.
                    </p>
                </div>
                <button className="faq-item" onClick={this.handleItemClick} >
                    - I have a question not answered here, how do I contact you? -
                </button>
                <div className="faq-item-content">
                    <p>
                        Hopefully we have answered most of your questions, but if not, please drop us an&nbsp;
                        <a href="mailto:jim.draycott@googlemail.com; michelecaneppele@hotmail.com?subject=Wedding">email</a>.
                    </p>
                </div>
            </div>
        )
    }
}

export default FAQ;