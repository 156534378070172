
import Airtable from "airtable";
const _apiKey = 'keydyv2eOoPtuVMI7';
const _baseId = 'appzAbsUxVRVluLzo';

export class service {

   static saveRSVP (guestNames = [],
                    attendancePizza = '',
                    attendanceCeremony = '',
                    attendancePool = '',
                    weddingMealMeat = [],
                    weddingMealFish = [],
                    weddingMealVeg = [],
                    dietaryReqs = '',
                    iceCreamSuggestion = '',
                    stayOnSite = "") {

        if (guestNames.join().trim().length > 0 && attendanceCeremony.trim().length > 0) {
            const base = new Airtable({apiKey: _apiKey}).base(_baseId);
            base('RSVPs').create([
                {
                    "fields": {
                        "names": guestNames.join(', '),
                        "count": guestNames.length,
                        "attendance_pizza": attendancePizza,
                        "attendance_ceremony": attendanceCeremony,
                        "attendance_pool": attendancePool,
                        "wedding_meal_meat": weddingMealMeat.join(', '),
                        "wedding_meal_meat_count": weddingMealMeat.length,
                        "wedding_meal_fish": weddingMealFish.join(', '),
                        "wedding_meal_fish_count": weddingMealFish.length,
                        "wedding_meal_veg": weddingMealVeg.join(', '),
                        "wedding_meal_veg_count": weddingMealVeg.length,
                        "dietary_reqs": dietaryReqs,
                        "ice_cream_suggestion": iceCreamSuggestion,
                        "stay_on_site": stayOnSite
                    }
                }
            ], function (err, records) {
                if (err) {
                    console.error(err);
                    return false;
                }
                // records.forEach(function (record) {
                //     //console.log(record.getId());
                // });
            });
            return true;
        } else { return false; }
    }

}