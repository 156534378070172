import React, {Component} from 'react';
import PropTypes from 'prop-types';

class CopyToClipboard extends Component {
    static defaultProps = {
        CopyToClipboardElementID: '',
        title: '',
        float: 'none'
    }

    constructor(props) {
        super(props);
        this.state = {
            CopyToClipboardContentElementID: props.CopyToClipboardContentElementID,
        }
    }

    handleCopyToClipboard() {
        if (this.props.CopyToClipboardContentElementID.length > 0) {
            /* Get the text from the specified element */
            const copyText = document.getElementById(this.props.CopyToClipboardContentElementID).innerText;
            console.log(copyText)
            navigator.clipboard.writeText(copyText).then();
        }
    }

    render() {
        const _buttonStyle = {
                width: '20px',
                height: '20px',
                backgroundColor: 'transparent',
                border: 'none',
                padding: '0',
                cursor: 'pointer',
                verticalAlign: 'text-bottom',
                float: this.props.float
            },
            _imgStyle = {
                width: '20px',
                height: '20px',
            };
        return (
            <button style={_buttonStyle} onClick={() => this.handleCopyToClipboard()}>
                <img style={_imgStyle} title={this.props.title} alt='' src="/assets/images/copy.svg"/>
            </button>
        );
    }
}

CopyToClipboard.propTypes = {
    CopyToClipboardContentElementID: PropTypes.string.isRequired,
    float: PropTypes.oneOf(['left', 'none', 'right'])
}

export default CopyToClipboard;