import React, {Component} from 'react';
import './Nav.css';


class Nav extends Component {
    goToSection(sectionId) {
        document.getElementById(sectionId).scrollIntoView({behavior: "smooth"});
    }

    goToSectionMobile(sectionId) {
        document.getElementById("chkBurger").checked = false;
        this.goToSection(sectionId);
    }

    openModal(modalId) {
        document.getElementById(modalId).style.display = "block";
    }

    openModalMobile(modalId) {
        document.getElementById("chkBurger").checked = false;
        this.openModal(modalId);
    }

    Logout = () => {
        sessionStorage.clear();
        window.location.reload(false);
    }

    DesktopMenu() {
        return (
            <div id="navbar-main">
                <div className="ribbon" onClick={() => this.goToSection('rsvp')}><span>RSVP</span></div>
                <button className="nav-item" onClick={() => this.goToSection('WelcomeMsg')}>Welcome</button>
                <div className="dd-container">
                    <button className="nav-item">Our Story</button>
                    <div className="dd-content">
                        <button className="dd-item" onClick={() => this.goToSection('GrowingUp')}>Growing Up</button>
                        <button className="dd-item" onClick={() => this.goToSection('FirstDate')}>First Date</button>
                        <button className="dd-item" onClick={() => this.goToSection('Proposal')}>The Proposal</button>
                        <button className="dd-item" onClick={() => this.openModal('galleryModal')}>Gallery</button>
                    </div>
                </div>
                <div className="dd-container">
                    <button className="nav-item">The Wedding</button>
                    <div className="dd-content">
                        <button className="dd-item" onClick={() => this.goToSection('Venue')}>The Venue</button>
                        <button className="dd-item" onClick={() => this.goToSection('Travel')}>Travel</button>
                        <button className="dd-item" onClick={() => this.goToSection('Accommodation')}>Accommodation</button>
                        <button className="dd-item" onClick={() => this.goToSection('Schedule')}>Wedding Schedule</button>
                        <button className="dd-item" onClick={() => this.goToSection('WeddingParty')}>Wedding Party</button>
                        <button className="dd-item" onClick={() => this.goToSection('Registry')}>Registry</button>
                        <button className="dd-item" onClick={() => this.goToSection('Playlist')}>Song for the dance floor</button>
                    </div>
                </div>
                <button className="nav-item" onClick={() => this.goToSection('ContactUs')}>Contact Us</button>
                <button className="nav-item" onClick={() => this.openModal('faqModal')}>Q&amp;A</button>
                <button className="nav-item" onClick={this.Logout}>Log Out</button>
            </div>
        )
    }

    MobileMenu() {
        return (
            <div id="navbar-mobile">
                <div id="menu-burger">
                    <input id="chkBurger" type="checkbox"/>
                    <span className="top"/>
                    <span className="middle"/>
                    <span className="bottom"/>
                    <div id="mobile-menu">
                        <button className="l1" onClick={() => this.goToSectionMobile('WelcomeMsg')}>Welcome</button>
                        <span className="l1">Our Story</span>
                        <button className="l2" onClick={() => this.goToSectionMobile('GrowingUp')}>Growing Up</button>
                        <button className="l2" onClick={() => this.goToSectionMobile('Proposal')}>The Proposal</button>
                        <button className="l2" onClick={() => this.goToSectionMobile('FirstDate')}>First Date</button>
                        <button className="l2" onClick={() => this.openModalMobile('galleryModal')}>Gallery</button>
                        <span className="l1">The Wedding</span>
                        <button className="l2" onClick={() => this.goToSectionMobile('Venue')}>The Venue</button>
                        <button className="l2" onClick={() => this.goToSectionMobile('Travel')}>Travel</button>
                        <button className="l2" onClick={() => this.goToSectionMobile('Accommodation')}>Accommodation</button>
                        <button className="l2" onClick={() => this.goToSectionMobile('Schedule')}>Wedding Schedule
                        </button>
                        <button className="l2" onClick={() => this.goToSectionMobile('WeddingParty')}>Wedding Party
                        </button>
                        <button className="l2" onClick={() => this.goToSectionMobile('Registry')}>Registry</button>
                        <button className="l2" onClick={() => this.goToSectionMobile('Playlist')}>
                            Song for the dance floor
                        </button>
                        <button className="l1" onClick={() => this.goToSectionMobile('rsvp')}>RSVP</button>
                        <button className="l1" onClick={() => this.goToSectionMobile('ContactUs')}>Contact Us</button>
                        <button className="l1" onClick={() => this.openModalMobile('faqModal')}>Q&amp;A</button>
                        <button className="l1" onClick={this.Logout}>Log Out</button>
                    </div>
                </div>
            </div>
        )
    }

    render() {
        return (
            <div id="navbar">
                {this.DesktopMenu()}
                {this.MobileMenu()}
            </div>
        );
    }
}

export default Nav;