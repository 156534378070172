import React, {Component} from 'react';
import Airtable from "airtable";
import './Form.css';

class FormPlaylist extends Component {
    constructor(props) {
        super(props);
        this.state = {
            requester: '',
            artist: '',
            song: '',
            songSubmit: 'Add song to the Playlist'
        }

        this.handleSongInputChange = this.handleSongInputChange.bind(this);
        this.handleSongSubmit = this.handleSongSubmit.bind(this);
    }

    handleSongInputChange(e) {
        const target = e.target,
            value = target.value,
            name = target.name;
        this.setState({[name]: value});
    }

    handleSongSubmit(e) {
        const _requester = this.state.requester, _artist = this.state.artist, _song = this.state.song;
        if (_requester.trim().length > 0 && _artist.trim().length > 0 && _song.trim().length > 0) {
            const base = new Airtable({apiKey: 'keydyv2eOoPtuVMI7'}).base('appzAbsUxVRVluLzo');
            base('Music Requests').create([
                {
                    "fields": {
                        "requester": _requester,
                        "artist": _artist,
                        "song": _song
                    }
                }
            ], function (err, records) {
                if (err) {
                    console.error(err);
                    return;
                }
                records.forEach(function (record) {
                    //console.log(record.getId());
                });
            });
            this.setState({requester: '', artist: '', song: '', songSubmit: "Add another song to the Playlist"});
        }
        e.preventDefault();
    }

    render() {
        return (
            <div id="Playlist" className="section-content">
                <h2>Song for the dance floor</h2>
                <p>
                    Please suggest a song to dance to <br/>
                    Or add to our <a href="https://open.spotify.com/playlist/5KqZlJSjEyvx3is3ncTBfu?si=YD1mlDo9SfW1Q5dwIUjYTw" target="_blank">Spotify Playlist</a>
                </p>
                <form className="form" onSubmit={this.handleSongSubmit}>
                    <label>
                        Your Name:
                        <input type="text" name="requester" value={this.state.requester}
                               onChange={this.handleSongInputChange} autoComplete="off"/>
                    </label>
                    <label>
                        Artist:
                        <input type="text" name="artist" value={this.state.artist}
                               onChange={this.handleSongInputChange} autoComplete="off"/>
                    </label>
                    <label>
                        Song:
                        <input type="text" name="song" value={this.state.song}
                               onChange={this.handleSongInputChange} autoComplete="off"/>
                    </label>
                    <button type="submit">{this.state.songSubmit}</button>
                </form>
            </div>
        );
    }
}

export default FormPlaylist;

