import React, {Component} from "react";
import {galleryData} from "./GalleryData";
import "./Gallery.css";

class Gallery extends Component {
    constructor(props) {
        super(props);
        this.state = {
            galleryHeroIndex: 0
        }
    }

    galleryHero(galleryItem) {
        let _currGalleryItemNum = this.state.galleryHeroIndex + 1;
        let _galleryTotal = galleryData.length;
        return (
            <div className="galleryHeroItem">
                {this.getImageVidHTML(galleryItem)}
                <p className="counter">{_currGalleryItemNum} / {_galleryTotal}</p>
                <p className="title">{galleryItem.title}</p>
            </div>
        );
    }

    galleryThumbs() {
        return (
            galleryData.map((galleryItem,index) => (
                <div id={index} key={index} className="galleryThumbItem" onClick={()=>this.changeGalleryHero(index)}>
                    {this.getImageVidHTML(galleryItem)}
                </div>
            ))
        );
    }

    getImageVidHTML(galleryItem){
        switch (galleryItem.type) {
            case "image" :
                return (
                    <img src={galleryItem.url} alt={galleryItem.title} title={galleryItem.title} />
                )
            case "video" :
                return (
                    <video controls autoPlay>
                        <source src={galleryItem.url} type="video/mp4" title={galleryItem.title}/>
                    </video>
                )
            default : return
        }
    }

    changeGalleryHero(i){
        this.galleryHero(galleryData[i])
        this.setState({ galleryHeroIndex: i });
    }

    changeNextPrevGalleryItem(direction) {
        let _galleryHeroIndex = this.state.galleryHeroIndex;
        const _galleryHeroIndexMax = galleryData.length - 1;
        switch (direction) {
            case "Prev" :
                if (_galleryHeroIndex > 0) {this.changeGalleryHero(_galleryHeroIndex - 1)}
                else {this.changeGalleryHero(_galleryHeroIndexMax)};
                break;
            case "Next" :
                if (_galleryHeroIndex < _galleryHeroIndexMax) {this.changeGalleryHero(_galleryHeroIndex + 1)}
                else (this.changeGalleryHero(0))
                break;
            default: break;
        }
    }

    changeThumbScroll(direction) {
        console.log('changeThumbScroll: ' + direction)
    }

    render() {
        return (
            <div className="galleryContainer">
                <div className="galleryHero">
                    {this.galleryHero(galleryData[this.state.galleryHeroIndex])}
                    <div className="galleryHeroPrev" onClick={()=>this.changeNextPrevGalleryItem("Prev")}>&lt;</div>
                    <div className="galleryHeroNext" onClick={()=>this.changeNextPrevGalleryItem("Next")}>&gt;</div>
                </div>
                <div className="galleryThumbs" style={{display: "none"}}>
                    {this.galleryThumbs()}
                </div>
            </div>
        );
    }
}

export default Gallery;

